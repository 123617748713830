import style from "./thirdpage.module.css";
import React, { useState, useEffect, useRef } from "react";
function Thirdpage() {
  const TOTAL_SLIDES = 3;

  const [current, setCurrent] = useState(0);
  const ref = useRef(null);

  const next = () => {
    if (current >= TOTAL_SLIDES) return;
    else setCurrent(current + 1);
  };

  const prev = () => {
    if (current === 0) return;
    else setCurrent(current - 1);
  };

  const desired = (e) => {
    setCurrent(Number(e.target.id));
  };

  useEffect(() => {
    ref.current.style.transition = "all 0.2s ease-in-out";
    ref.current.style.transform = `translateX(-${current}00%)`;
    ref.current.style.display = `flex`;
  }, [current]);

  return (
    <div className={style.main} id="news">
      <div className={style.wrapper}>
        <div className={style.head}>Server News</div>
        <div className={style.frame}>
          <div className={style.box_container} ref={ref}>
            <div className={`${style.box} ${style.img1}`}>
              <div className={style.box_date}>
                <div className={style.box_square}></div>
                <div className={style.box_text}>17 Feb - 2023</div>
              </div>
              <div className={style.box_arrows}>
                <div className={style.box_left} onClick={prev}></div>
                <div className={style.box_right} onClick={next}></div>
              </div>
              <div className={style.box_main}>
                <div className={style.box_main_header}>
                  Bedwars Update 0.1.5
                </div>
                <div className={style.box_main_desc}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecena saccumsan lacus vel facilisis. Risus commodo viverra
                  maecenas.
                </div>
              </div>
            </div>
            <div className={`${style.box} ${style.img2}`}>
              <div className={style.box_date}>
                <div className={style.box_square}></div>
                <div className={style.box_text}>17 Feb - 2023</div>
              </div>
              <div className={style.box_arrows}>
                <div className={style.box_left} onClick={prev}></div>
                <div className={style.box_right} onClick={next}></div>
              </div>
              <div className={style.box_main}>
                <div className={style.box_main_header}>
                  Bedwars Update 0.1.5
                </div>
                <div className={style.box_main_desc}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecena saccumsan lacus vel facilisis. Risus commodo viverra
                  maecenas.
                </div>
              </div>
            </div>
            <div className={`${style.box} ${style.img3}`}>
              <div className={style.box_date}>
                <div className={style.box_square}></div>
                <div className={style.box_text}>17 Feb - 2023</div>
              </div>
              <div className={style.box_arrows}>
                <div className={style.box_left} onClick={prev}></div>
                <div className={style.box_right} onClick={next}></div>
              </div>
              <div className={style.box_main}>
                <div className={style.box_main_header}>
                  Bedwars Update 0.1.5
                </div>
                <div className={style.box_main_desc}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecena saccumsan lacus vel facilisis. Risus commodo viverra
                  maecenas.
                </div>
              </div>
            </div>
            <div className={`${style.box} ${style.img4}`}>
              <div className={style.box_date}>
                <div className={style.box_square}></div>
                <div className={style.box_text}>17 Feb - 2023</div>
              </div>
              <div className={style.box_arrows}>
                <div className={style.box_left} onClick={prev}></div>
                <div className={style.box_right} onClick={next}></div>
              </div>
              <div className={style.box_main}>
                <div className={style.box_main_header}>
                  Bedwars Update 0.1.5
                </div>
                <div className={style.box_main_desc}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecena saccumsan lacus vel facilisis. Risus commodo viverra
                  maecenas.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.button_container}>
          {/* <div className={style.button} onClick={prev}>Left</div>
        <div className={style.button} onClick={next}>Right</div> */}
        </div>
        <div className={style.button_2_container}>
          {[0, 1, 2, 3].map((num) => (
            <div
              className={`${style.button_2} ${num === current && "active"}`}
              onClick={desired}
              id={num}
              key={num}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Thirdpage;
