import style from "./firstpage.module.css";
function Firstpage() {
  return (
    <div className={style.main}>
      <div className={style.container}>
        <div className={style.left}>
          <div className={style.left_main}>
            Nemsiscraft Network Simple minecraft server{" "}
          </div>
          <div className={style.left_desc}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
            ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
            accumsan lacus vel facilisis.{" "}
          </div>
          <div className={style.left_btn_cont}>
            <a className={style.left_btn} href="#">
              PLAY.NEMSISCRAFT.COM
            </a>
            <div className={style.left_online_cont}>
              <div className={style.left_online_icon}></div>
              <div className={style.left_online_text}>142 Players Online</div>
            </div>
          </div>
        </div>
        <div className={style.right}></div>
        <div className={style.bottom}>
          <div className={style.bottom_desc}>Server Team</div>
          <div className={style.bottom_team}>
            <div className={style.bottom_team_member}>
              <div className={style.bottom_team_member_img1}></div>
              <div className={style.bottom_team_member_name}>GamesNemsis</div>
            </div>
            <div className={style.bottom_team_member}>
              <div className={style.bottom_team_member_img2}></div>
              <div className={style.bottom_team_member_name}>Mike_Robox</div>
            </div>
            <div className={style.bottom_team_member}>
              <div className={style.bottom_team_member_img3}></div>
              <div className={style.bottom_team_member_name}>ByGio</div>
            </div>
            <div className={style.bottom_designline}></div>
            <div className={style.bottom_pocket}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Firstpage;
