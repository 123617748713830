import style from "./footer.module.css";
function Footer() {
  return (
    <div className={style.main}>
      <div className={style.container}>
        <div className={style.leftside}>
          <div className={style.leftside_header}>
            2023 All rights reserved -{" "}
            <span className={style.nemsis}>Nemsiscraft</span> network
          </div>
          <a className={style.leftside_digitalhub} href="http://digital-hub.ge">
            Powered by digital-hub.ge
          </a>
        </div>
        <div className={style.rightside}> </div>
      </div>
    </div>
  );
}

export default Footer;
