import style from "./fourthpage.module.css";

function Fourthpage() {
  return (
    <div className={style.main}>
      <div className={style.container}>
        <div className={style.leftside}>
          <div className={style.leftside_header}>NEMSISCRAFT DISCORD</div>
          <div className={style.leftside_desc}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do
            eiusmod tempor incididunt ut labore et dolore.
          </div>
          <a className={style.leftside_button} href="#">
            CLICK HERE TO JOIN
          </a>
        </div>
        <div className={style.rightside}></div>
      </div>
    </div>
  );
}

export default Fourthpage;
