// Design And Code 
// Made By Digital-hub.ge

import './App.css'
import Header from './components/header/header'
import Firstpage from './components/firstpage/firstpage'
import Secondpage from './components/secondpage/secondpage'
import Thirdpage from './components/thirdpage/thirdpage'
import Fourthpage from './components/fourthpage/fourthpage'
import Footer from './components/footer/footer'
function App() {
  return (

    <div className="App">
      <Header/>
      <Firstpage/>
      <Secondpage/>
      <Thirdpage/>
      <Fourthpage/>
      <Footer/>
    </div>

  );
}

export default App;

// Design And Code 
// Made By Digital-hub.ge