import style from "./header.module.css";
function Header() {
  const gotoservers = () => {
    const element = document.getElementById("servers");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const gotonews = () => {
    const element = document.getElementById("news");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={style.main}>
      <div className={style.container}>
        <div className={style.left}>
          <a className={style.logobox} href="#"></a>
        </div>
        <div className={style.middle}>
          <div className={style.middle_cont}>
            <div className={`${style.item} ${style.fix}`}>
              <div className={style.item_main_line}></div>
              <a className={style.item_text} href="#">
                MAIN PAGE
              </a>
            </div>
            <div className={style.item}>
              <div className={style.item_main_line}></div>
              <a className={style.item_text} onClick={gotoservers}>
                SERVERS
              </a>
            </div>
            <div className={style.item}>
              <div className={style.item_main_line}></div>
              <a className={style.item_text} href="#">
                DONATION
              </a>
            </div>
            <div className={style.item}>
              <div className={style.item_main_line}></div>
              <a className={style.item_text} onClick={gotonews}>
                ABOUT US
              </a>
            </div>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.right_cont}>
            <a className={style.discord} href="#"></a>
            <a className={style.youtube} href="#"></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
