import style from "./secondpage.module.css";

function Secondpage() {
  return (
    <div className={style.main} id="servers">
      <div className={style.container}>
        <div className={style.desc}>Our Servers</div>
        <div className={style.lister}>
          <div className={style.lister_item}>
            <div className={style.img1}></div>
            <div className={style.lister_text_cont}>
              <div className={style.lister_text1}>Skywars</div>
              <div className={style.lister_text2}>
                Classic multiplayer minigame
              </div>
            </div>
          </div>
          <div className={style.lister_item}>
            <div className={style.img2}></div>
            <div className={style.lister_text_cont}>
              <div className={style.lister_text1}>Skywars</div>
              <div className={style.lister_text2}>
                Classic multiplayer minigame
              </div>
            </div>
          </div>
          <div className={style.lister_item}>
            <div className={style.img3}></div>
            <div className={style.lister_text_cont}>
              <div className={style.lister_text1}>Skywars</div>
              <div className={style.lister_text2}>
                Classic multiplayer minigame
              </div>
            </div>
          </div>
          <div className={style.lister_item}>
            <div className={style.img4}></div>
            <div className={style.lister_text_cont}>
              <div className={style.lister_text1}>Skywars</div>
              <div className={style.lister_text2}>
                Classic multiplayer minigame
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Secondpage;
